$white: #FFF;
$black: #252525;
$background-header:rgba(#2e3342,.6);
$background-footer: $white;
$background: $white;
$title: #2F313C;
$red:#DC0039; 
$text-box: #898989;
$element: #FBECCD;
$shadow: #E4D2AE;
$box:  #eff3f4;
$box-input:  #f2fafa;
$blue: #0700ff;
$title-button: #0C0C0C;
$title-box: #1B3F5B;

@mixin main-column {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 50px 20px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 40px 10px;
    }

    @media(max-width: 425px){
        padding: 30px 10px;
    }
}

@mixin box-column {
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin title {
    font-family:'Trajan';
    text-align: center;
    font-weight: 400;
    font-size: 37px;
    line-height: 1.2;
    color: $title-box;
    margin: 0;

    @media(max-width: 768px){
        font-size: 24px;
    }

    @media(max-width: 425px){
            font-size: 20px;
        }
}

@mixin text {
    color: $white;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;

    @media(max-width: 768px){
        font-size: 16px;
}
}

@mixin button{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 0;
    color: $title-button;
    width: 180px;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 4px;
    background: $element;
    background-size: 200%;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}


@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 10px;
        }
}



