
@font-face {
	font-family:'Trajan';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("Trajan.woff2") format("woff2"),
		url("Trajan.woff") format("woff");
}

@font-face {
	font-family:'Gilroy';
	font-style:  normal;
	font-weight: 700;
	font-display: swap;
	src: url("Gilroy-Bold.woff2") format("woff2"),
		url("Gilroy-Bold.woff") format("woff");
}

@font-face {
	font-family:'Gilroy';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("Gilroy-Regular.woff2") format("woff2"),
		url("Gilroy-Regular.woff") format("woff");
}

@font-face {
	font-family:'Gilroy';
	font-style:  normal;
	font-weight: 300;
	font-display: swap;
	src: url("Gilroy-Light.woff2") format("woff2"),
		url("Gilroy-Light.woff") format("woff");
}