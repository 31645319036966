@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    background-image: url(../../image/AboutUs/bg.webp);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    margin-top: -120px;
}

.container{
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
    padding: 50px 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.bush{
    max-width: 300px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: -20px;
}

.box_info{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 100px 0 50px;
    z-index: 1;
}


.title{
    font-family:'Trajan';
    text-align: left;
    font-weight: 400;
    font-size: 37px;
    line-height: 1.2;
    color: $title-box;
    margin: 0;
}

.text{
    color: $text-box;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0 0;
}

.box_image{
    max-width: 40%;
    width: 100%;
    margin: 20px 0 0 0;
    box-sizing: border-box;
    aspect-ratio: 2/3;
    position: relative;
    object-fit: cover;
}


.image{
    width: 100%;
    height: 100%;
    aspect-ratio: 2/3;
    object-fit: contain;
}

.box_profession{
    width: 90%;
    position: absolute;
    bottom: -5px;
    left: 0;
    box-sizing: border-box;
    background: $element;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: skew(-10deg);
}

.subtitle{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    color: $title-button;
    margin: 10px 0 5px;
}

.profession{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 1px;
    color: $title-button;
    margin: 0 0 10px;
}

.button{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 300px;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}


@media(max-width: 1400px){
    .title{
        max-width: 650px;
        width: 100%;
        font-size: 30px;
    }
}

@media(max-width: 1150px){

    .box_info{
        margin: 70px 0 50px;
    }


    .title{
        max-width: 570px;
        font-size: 24px;
    }

    .box_image{
        max-width: 50%;
    }

    .text{
        font-size: 16px;
    }

    .bush{
        max-width: 200px;
    }
}

@media(max-width: 850px){
    .box_info{
        margin: 30px 0 50px;
    }

    .title{
        max-width: 500px;
        font-size: 22px;
    }
}



@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;   
        padding: 40px 10px 0;
    }

    .title{
        max-width: 100%;
    }

    .text{
        font-size: 16px;
    }

    .box_image{
        max-width: 70%;
        margin: 50px 0 0;
    }
    
    .box_info{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 100px; 
    }
}

@media(max-width: 450px){

    .title{
        font-size: 20px;
    }
}

@media(max-width: 425px){

    .container{
    padding: 30px 10px 0;
    }

    .box_image{
        max-width: 90%;
    }

    .title{
        font-size: 18px;
    }
}