@import '../../base.scss';

.main{
    width: 100%;
    background-image: url(../../image/Discount/bg.webp);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-color: transparent;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    padding: 30px 0 0;
}

.box{
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    padding: 0 20px;
}

.image{
    width: 50%;
    aspect-ratio: 3/4;
}

.container{
    max-width: 45%;
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding: 10px;
}

.title{
    font-family:'Trajan';
    max-width: 300px;
    width: 100%;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 400;
    text-align: left;
    margin: 10px 0 10px;
    color: $title-box;
}

.text{
    font-family:'Gilroy';
    max-width: 400px;
    width: 100%;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: left;
    margin: 0 0 20px;
    color: $title-box;
}


.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #F2F2F2;
    border-radius: 6px;
}

.box_input{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 60px;
    background-color: #F6F8FB;
    border-radius: 40px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 10px;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    box-sizing: border-box;
    width: 100%;
    color: $text-box;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 8px; 
}

.input{
    box-sizing: border-box;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
}

.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.button_form{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}

.button_disabled{
    pointer-events: none;
    cursor: default;
}

.list{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.item{
    box-sizing: content-box;
}


.link{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $element;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}


.image_icon{
    width: 20px;
    height: 20px;
}


@media(max-width: 850px){
    .title{
        max-width: 300px;
        font-size: 22px;
        margin: 10px 0 10px;
    }
    
    .text{
        max-width: 400px;
        font-size: 14px;
        margin: 0 0 10px;
    }
}

@media(max-width: 768px){
    .box{
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
    }

    .image{
        width: 80%;
        aspect-ratio: 3/4;
    }

    .container{
        max-width: 100%;
        box-sizing: border-box;
    }
}