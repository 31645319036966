@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0;
    background-color: $white;
}

.title{
    font-family:'Trajan';
    max-width: 500px;
    width: 100%;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 400;
    text-align: left;
    margin: 0;
    color: $title-box;
}

.box{
    @include main-column;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
}

.box_info{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text{
    font-family:'Gilroy';
    max-width: 400px;
    width: 100%;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: left;
    margin: 20px 0 0;
    color: $title-box;
}

.button{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 300px;
    height: 60px;
    margin: 30px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}

.image{
    width: 100%;
    height: auto;
    margin-top: -15px;
}

.card{
    max-width: 50%;
    width: 100%;
}

@media(max-width: 850px){
    .title{
        font-size: 22px;
    }
    
    .text{
        font-size: 14px;
    }
}

@media(max-width: 768px){
    .box{
        flex-direction: column-reverse;
        gap: 30px;
    }

    .card{
        max-width: 90%;
    }

    .box_info{
        max-width: 90%;
    }

    .title{
        min-width: 100%;
    }
    
    .text{
        max-width: 100%;;
    }
}

@media(max-width: 425px){

    .card{
        max-width: 100%;
    }

    .box_info{
        max-width: 100%;
    }
}