@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0;
    background-color: #F8F7FC
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
}

.list_link {
    box-sizing: border-box;
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin: 0 0 20px;
}

.item_link{
    font-size: 18px;
    line-height: 1.2;
    padding: 10px 15px;
    color: $title;
    text-transform: uppercase;
    cursor: pointer;

    &:hover{
        opacity: .5;
    }
}

/// меню

.item_blue{
    font-size: 18px;
    line-height: 1.2;
    box-sizing: border-box;
    background-color: rgba($element, .8);
    text-transform: uppercase;
    color: $title;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    overflow: hidden;
}


.list{
    @include list
}

.list_none {
    display: none;
}

.item{
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding: 10px;

    &:hover{
        cursor: pointer;
        transform:scale(1.02);
        transition: .5s;
    }
}

.box_info{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #F2F2F2;
    border-radius: 6px;
}

.item_title{
    font-family:'Trajan';
    width: 100%;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    margin: 0;
    color: $title-box;
}

.item_subtitle{
    font-family:'Trajan';
    text-transform: uppercase;
    width: 100%;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    margin: 10px 0 0;
    color: #757582;
}

.item_info{
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item_box{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
}

.icon{
    width: 26px;
    height: 26px;
}

.item_text{
    width: 100%;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    text-align: left;
    margin: 0;
    color: $title-box;
}

.price{
    font-family:'Trajan';
    width: 100%;
    font-size: 32px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
    margin: 20px 0 0;
    color: #FCCC6B;
}

.button{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 100%;
    height: 50px;
    margin: 20px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}

@media(max-width: 768px){

    .title{
        margin: 0 0 20px 0;
    }

    ///меню
    .list_link {
        gap: 10px;
    }
    
    .item_link{
        font-size: 14px;
        line-height: 1.2;
    }
    
    .item_blue{
        font-size: 14px;
        line-height: 1.2;
    }

    ////////////
    
    .list{
        gap: 10px;
    }

    .item_title{
        font-size: 22px;
    }

    .price{
        font-size: 22px;
    }
}

@media(max-width: 425px){
    .list_link {
        gap: 5px;
    }

    .item_link{
        font-size: 14px;
        line-height: 1.2;
        padding: 10px 10px;
    }
    
    .item_blue{
        font-size: 14px;
        line-height: 1.2;
        padding: 10px 10px;
    }
}

@media(max-width: 360px){

    .item_link{
        font-size: 14px;
        line-height: 1.2;
        padding: 6px 6px;
    }
    
    .item_blue{
        font-size: 14px;
        line-height: 1.2;
        padding: 6px 6px;
    }
}