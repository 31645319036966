@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.box{
    @include main-column;
}

.item{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    padding: 20px 20px 40px;
}

.title{
    @include title;
    z-index: 1;
    margin: 0;
}

.text{
    width: 100%;
    color: $text-box;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    margin: 10px 0 0;

    & span {
        font-weight: 700;
        color: #e97e9a;
    }
}

.box{
    @include main-column;
}

@media(max-width: 768px){

    .title{
        margin: 0;
    }
    
    .text{
        font-size: 16px;
    }
}