@import '../../base.scss';


.main {
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.overlay{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.box{
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.container{
    box-sizing: border-box;
    max-width: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}


.item {
    background-image: url(../../image/InitialPage/img.webp);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: relative;
}

.logo{
    width: 250px;
    height: auto;
}

.logo_min{
    display: none;
}

.image{
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    right: -150px;
}

.title{
    max-width: 550px;
    font-family:'Trajan';
    text-align: left;
    font-size: 56px;
    line-height: 1.2;
    font-weight: 400;
    margin: 100px 0 0;
    position: relative;
    color: $title;
    z-index: 1;
}

.subtitle{
    max-width: 550px;
    font-family:'Trajan';
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.4;
    font-weight: 400;
    text-align: left;
    margin: 0;
    color: $title-button;
    margin: 20px 0;
    z-index: 1;
}

.text{
    max-width: 450px;
    text-align: left;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    color: $title-button;
    margin: 0;
    z-index: 1;

    & span {
        color: $red;
    }
}

.button{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 300px;
    height: 60px;
    margin: 30px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}

@media(max-width: 1400px){
    .image{
        right: -200px;
    }
}

@media(max-width: 1300px){
    .container{
        max-width: 100%;
    }
}

@media(max-width: 1150px){
    .image{
        right: -250px;
    }

    .title{
        max-width: 550px;
        font-size: 50px;
        margin: 100px 0 0;
    }
}


@media(max-width: 1050px){
    .image{
        right: -350px;
    }
}

@media(max-width: 950px){
    .title{
        max-width: 550px;
        font-size: 40px;
        margin: 100px 0 0;
    }

    
.subtitle{
    max-width: 400px;
    font-size: 20px;
}

.text{
    max-width: 400px;
    font-size: 18px;
}
}

@media(max-width: 850px){
    .image{
        right: -450px;
    }
}

@media(max-width: 768px){
    .item {
        background-image: url(../../image/InitialPage/img_2.webp);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
    }

    .image{
        display: none;
    }

    .title{
        max-width: 450px;
        font-size: 38px;
        margin: 150px 0 0;
    }

}

@media(max-width: 425px){
    .container{
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
    }

    .logo{
        display: none;
    }
    
    .logo_min{
        display: block;
        width: 50px;
    }

    .title{
        max-width: 450px;
        font-size: 32px;
        margin: 150px 0 0;
    }

    .subtitle{
        max-width: 400px;
        font-size: 18px;
    }

    .text{
        max-width: 250px;
        font-size: 16px;
    }

    .button{
        width: 280px;
        height: 50px;
    }
}