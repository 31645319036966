@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.img{
    width: 100%;
    height: auto;
    margin-top: -15px;
}

.img_bottom{
    width: 100%;
    height: auto;
    margin-bottom: -15px;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
}

.list{
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.list_none {
    display: none;
}

.item{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    padding: 20px 20px 40px;
}

.cell{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 50%;
    width: 100%;
}

.image{
    width: 100%;
    height: auto;
    border-radius: 6px;
}

.text{
    position: absolute;
    left: 40%;
    bottom: -30px;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.2;
    color: $title-button;
    padding: 10px 20px;
    transform: skew(-10deg);
    margin: 0;
    background-color: $element;
    border-radius: 6px;
}

.button{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 300px;
    height: 60px;
    margin: 50px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}


/// меню
.list_link {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin: 0 0 20px;
}

.item_link{
    font-size: 18px;
    line-height: 1.2;
    padding: 10px 15px;
    color: $title;
    text-transform: uppercase;
    cursor: pointer;

    &:hover{
        opacity: .5;
    }
}

.item_blue{
    font-size: 18px;
    line-height: 1.2;
    box-sizing: border-box;
    background-color: rgba($element, .8);
    text-transform: uppercase;
    color: $title;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    overflow: hidden;
}

@media(max-width: 1020px){
    .text{
        padding: 5px 20px;
    }
}

@media(max-width: 768px){
    .item{
        flex-direction: column;
        gap: 40px;
    }

    .cell{
        max-width: 100%;
    }

    .button{
        font-family:'Trajan';
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 1px;
        padding: 0;
        color: $title-button;
        width: 300px;
        height: 60px;
        margin: 70px 0 0;
        border-radius: 16px;
        background: $element;
        border: none;
        transform: translateY(-5px);
        box-shadow: 0 4px 0 $shadow;
    }

     ///меню
    .list_link {
        gap: 10px;
    }
    
    .item_link{
        font-size: 14px;
        line-height: 1.2;
    }
    
    .item_blue{
        font-size: 14px;
        line-height: 1.2;
    }

    ////////////
}

@media(max-width: 425px){
    .text{
        left: 35%;
        bottom: -20px;
        font-size: 20px;
        padding: 5px 15px;
    }

    .list_link {
        gap: 5px;
    }

    .item_link{
        font-size: 14px;
        line-height: 1.2;
        padding: 10px 10px;
    }
    
    .item_blue{
        font-size: 14px;
        line-height: 1.2;
        padding: 10px 10px;
    }
}

@media(max-width: 360px){

    .item_link{
        font-size: 14px;
        line-height: 1.2;
        padding: 6px 6px;
    }
    
    .item_blue{
        font-size: 14px;
        line-height: 1.2;
        padding: 6px 6px;
    }
}