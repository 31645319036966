@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0;
}

.ribbon{
    position: absolute;
    top: 0;
    left: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
}

.box{
    @include main-column;
}

.image{
    width: 100%;
    height: auto;
    margin-top: -15px;
}

.img{
    width: 100%;
    height: auto;
    margin-bottom: -15px;
    z-index: 1;
}

.box_info{
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.box_skills{
    display: none;
}

.foto{
    width: 100%;
    aspect-ratio: 3/4;
}

.button{
    font-family:'Trajan';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    padding: 0;
    color: $title-button;
    width: 400px;
    height: 60px;
    margin: -10px 30px 0 0;
    border-radius: 16px;
    background: $element;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 4px 0 $shadow;

    &:hover{
        cursor: pointer;
        box-shadow: 0 0 0 $shadow;
        transition: .5s;
        
    }
}

.text{
    box-sizing: border-box;
    max-width: 180px;
    text-align: center;
    padding: 0 5px;
}

.item_one{
    max-width: 250px;
    height: 190px;
    width: 100%;
    position: absolute;
    top: 20px;
    left: -100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../image/Skills/cell.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.item_two{
    max-width: 250px;
    height: 190px;
    width: 100%;
    position: absolute;
    top: 20px;
    right: -100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../image/Skills/cell.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.item_three{
    max-width: 250px;
    height: 190px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: -170px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../image/Skills/cell.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.item_four{
    max-width: 250px;
    height: 190px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: -200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../image/Skills/cell.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.item_five{
        max-width: 250px;
        height: 190px;
        width: 100%;
        position: absolute;
        bottom: 40%;
        left: -200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../../image/Skills/cell.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
}

.item_six{
    max-width: 250px;
        height: 190px;
        width: 100%;
        position: absolute;
        bottom: 40%;
        right: -200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../../image/Skills/cell.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
}

@media(max-width: 900px){
    .box_info{
        max-width: 400px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .foto{
        width: 90%;
        aspect-ratio: 3/4;
    }

    .text{
        box-sizing: border-box;
        max-width: 170px;
        text-align: center;
        padding: 0 5px;
    }

    .item_one{
        max-width: 210px;
        top: 20px;
        left: -100px;
    }

    .item_two{
        max-width: 210px;
        top: 20px;
        right: -100px;
    }

    .item_three{
        max-width: 210px;
        bottom: 20px;
        right: -170px;
    }
    
    .item_four{
        max-width: 210px;
        bottom: 20px;
        left: -170px;
    }
    
    .item_five{
            max-width: 210px;
            bottom: 33%;
            left: -160px;
    }
    
    .item_six{
        max-width: 210px;
            bottom: 33%;
            right: -160px;
    }

    .button{
        width: 320px;
    }
}

@media(max-width: 730px){
    .box_info{
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .item_one{
        display: none;
    }

    .item_two{
        display: none;
    }

    .item_three{
        display: none;
    }
    
    .item_four{
        display: none;
    }
    
    .item_five{
        display: none;
    }
    
    .item_six{
        display: none;
    }

    .button{
        width: 320px;
    }

    .box_skills{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .box_skills{
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
    }

    .skills{
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        background-color: $white;
        box-shadow: 0 0 3px #1B3F5B;
        border-radius: 6px;
        padding: 10px;
        margin-bottom: 10px; 
    }

    .item_box{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        padding: 20px;
        border: 1px solid #F2F2F2;
    }
    
    .icon{
        width: 26px;
        height: 26px;
    }
    
    .item_text{
        width: 100%;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        text-align: left;
        margin: 0;
        color: $title-box;
    }

    .button{
        width: 80%;
        margin: 0;
    }
}


@media(max-width: 450px){
    .button{
        width: 300px;
        height: 60px;
        margin: -10px 30px 0 0;
    }
}

@media(max-width: 400px){
    .button{
        margin: -10px 0 0 0;
    }
}