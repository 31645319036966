@import '../../base.scss';

.header {
   height: 0px;
}



@media(max-width: 768px){
    .logo{
        width: 200px;
        height: auto;
    }

    .box_logo{
        padding-bottom: 0;
    }

    .list{
        display: none;
    }

    .contacts{
        max-width: 150px;
        margin-left: 10px;
    }


    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $white);
            transition: all .3s ease-out;
        }
    }
}

@media(max-width: 425px){

    .logo_min{
        display: block;
        width: 40px;
        height: auto;
    }

    .logo{
        display: none;
    }
}