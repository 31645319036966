@import '../../base.scss';

    .main{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .box_map{
        max-width: 100%;
        width: 100%;
        aspect-ratio: 4/1;
    }

    @media(max-width: 768px){
    
        .box_map{
            aspect-ratio: 1/1;
        }
    }